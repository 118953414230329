import React from 'react';
import {Container, Row, Col, Button} from 'react-bootstrap';
import content from 'commons/content.json';
import './home.css';

const tv = content.home.branding;

const OwnBrand = () => {
  return (
    <Container fluid id="bg-brand">
      <Container>
        <Row>
          <Col md="5" className="brand-v">
            <p className="display-4 brand">
              <h4>
                <small className="text-muted">{tv.subtitle}</small>
              </h4>
              {tv.title}
            </p>
            <p className="h5">
              <small>{tv.description}</small>
            </p>
            <Button variant="primary" className="button-more">More</Button>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default OwnBrand;
