import React, {useState} from 'react';
import {ImageBackground, View} from 'react-native';
import {Image, Carousel} from 'react-bootstrap';

const imageSlide = [
  {id: 'intro', img: require('assets/tv-1a.jpg')},
  {id: 'dashboard', img: require('assets/tv-1b.jpg')},
  {id: 'playlist', img: require('assets/tv-1c.jpg')},
  {id: 'search', img: require('assets/tv-2.jpg')},
  {id: 'result', img: require('assets/tv-3.jpg')},
  {id: 'select', img: require('assets/tv-3a.jpg')},
  {id: 'video', img: require('assets/tv-4.jpg')},
];

const WebCarousel = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <View
      style={{
        width: 956,
        height: 534,
      }}>
      <ImageBackground
        source={require('assets/tv-mock.png')}
        style={{
          flex: 1,
          width: '100%',
        }}>
        <View
          style={{
            width: 790,
            height: 442,
            paddingTop: 3,
            marginLeft: 64,
          }}>
          <Carousel
            indicators={false}
            activeIndex={index}
            onSelect={handleSelect}>
            {imageSlide.map((v, i) => {
              return (
                <Carousel.Item key={v.id}>
                  <Image src={v.img} alt={v.id} />
                </Carousel.Item>
              );
            })}
          </Carousel>
        </View>
      </ImageBackground>
    </View>
  );
};
export default WebCarousel;
