import React from 'react';
import {TouchableOpacity, Linking} from 'react-native';
import {Container, Row, Col, Image} from 'react-bootstrap';
import View from './View';
import content from 'commons/content.json';
import './home.css';

const feature = [
  {
    id: 'db',
    icon: require('assets/db.png'),
    title: 'No Need Update Songs',
    desc: 'Daily update new songs database and show on your dashboard automaticly',
  },
  {
    id: 'tecnology',
    icon: require('assets/playlist.png'),
    title: 'Newest Technology',
    desc: 'Content streaming technology like youtube with multiple video resolution option',
  },
  {
    id: 'cost',
    icon: require('assets/artist.png'),
    title: 'Low Cost',
    desc: "Its very cut cost, No need personal computer and no need 6TB songs storage",
  },
  {
    id: 'setup',
    icon: require('assets/artist.png'),
    title: 'Easy to use',
    desc: "Easy to use and Simple setup just tap install from google playstore",
  },
  {
    id: 'sosmed',
    icon: require('assets/artist.png'),
    title: 'Social Media Community',
    desc: "Share the happy momment with your friends",
  },
];

const HeadLine = () => {
  const {title, subtitle, description} = content.home.headline;
  const onPress = () => {
    Linking.openURL(
      'https://play.google.com/store/apps/details?id=com.smartkaraoke',
      {
        target: '_blank',
        rel: 'noopener',
      },
    );
  };
  const renderFitur = (v, i) => {
    return (
      <View.Features
        key={v.id}
        icon={v.icon}
        desc={v.desc}
        title={v.title}
      />
    );
  };
  return (
    <Container fluid>
      <Col id="mainBg">
        <Container>
          <Row>
            <Col md="4">
              <div id="headline">
                <p className="display-4">{title}</p>
                <p className="h4">
                  <small>{subtitle}</small>
                </p>
                <p className="h5">
                  <small>{description}</small>
                </p>
                <TouchableOpacity onPress={onPress}>
                  <Image
                    alt="playstore"
                    src={require('assets/google-play.png')}
                    className="playstore"
                  />
                </TouchableOpacity>
              </div>
            </Col>
            <Col id="centered">
              <Image
                alt="mock"
                src={require('assets/mock1.png')}
                className="mock1"
              />
            </Col>
          </Row>
          <Row className="features">{feature.map(renderFitur)}</Row>
        </Container>
      </Col>
    </Container>
  );
};

export default HeadLine;
/*
 <div>
            <Image
              alt="mock"
              src={require('assets/smartIcon.png')}
              className="logoIcon"
            />
            <Image
              alt="slogan"
              src={require('assets/slogan.png')}
              className="slogan"
            />
          </div>
          */
