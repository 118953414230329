import _superagent from "superagent";
import superagentPromise from "superagent-promise";
import Api from "./api.json";

//const API_ROOT = Api.smartplay.url;

const API_ROOT = Api.smartplay.url;
const superagent = superagentPromise(_superagent, global.Promise);
const responseBody = (res) => res.body;
const handleErrors = (err) => {
  if (err && err.response && err.response.status === 401) {
    console.log("error service", err); // authStore.logout();
  }
  return err;
};

const Storage = {
  token: null,
};
//const encode = encodeURIComponent;

export default function useSuperAgent() {
  const tokenPlugin = (req) => {
    const bearer = Storage.token;
    //smartApp.log('tokenplugin', bearer);
    if (bearer) {
      req
        .set("Authorization", `Bearer ${bearer}`)
        .set("Smart-API-Type", "demo")
        .set('Access-Control-Allow-Origin', '*')
        .accept("application/json");
    }
  };

  const agent = {
    del: (url, body) =>
      superagent
        .del(`${API_ROOT}${url}`)
        .send(body)
        .use(tokenPlugin)
        .end(handleErrors)
        .then(responseBody),
    unlike: (url, body) =>
      superagent
        .del(`${API_ROOT}${url}`)
        .send(body)
        .use(tokenPlugin)
        .end(handleErrors)
        .then(responseBody),
    get: (url) =>
      superagent.get(`${API_ROOT}${url}`).use(tokenPlugin).end(handleErrors).then(responseBody),
    put: (url, body) =>
      superagent
        .put(`${API_ROOT}${url}`)
        .send(body)
        .use(tokenPlugin)
        .end(handleErrors)
        .then(responseBody),
    post: (url, body) =>
      superagent
        .post(`${API_ROOT}${url}`)
        .send(body)
        .use(tokenPlugin)
        .end(handleErrors)
        .then(responseBody),
  };

  const global = {
    del: (url, body) =>
      superagent.del(`${url}`).send(body).use(tokenPlugin).end(handleErrors).then(responseBody),
    unlike: (url, body) =>
      superagent.del(`${url}`).send(body).use(tokenPlugin).end(handleErrors).then(responseBody),
    get: (url) => superagent.get(`${url}`).use(tokenPlugin).end(handleErrors).then(responseBody),
    put: (url, body) =>
      superagent.put(`${url}`).send(body).use(tokenPlugin).end(handleErrors).then(responseBody),
    post: (url, body) =>
      superagent.post(`${url}`).send(body).use(tokenPlugin).end(handleErrors).then(responseBody),
  };
  return [agent, global];
}
