import React from 'react';
import {Col, Image} from 'react-bootstrap';
import WebCarousel from 'component/carousel';

const FeatureIcon = ({icon, title, desc}) => {
  return (
    <Col>
      <Image alt="slogan" src={icon} className="image-center" />
      <p className="text-center font-weight-bold title">{title}</p>
      <p className="text-center h5">
        <small>{desc}</small>
      </p>
    </Col>
  );
};
const Features = ({icon, title, desc}) => {
  return (
    <Col>
      <p className="font-weight-bold title">{title}</p>
      <p className="h5">
        <small>{desc}</small>
      </p>
    </Col>
  );
};
const Carousel = () => {
  return (
    <div className="carousel-tv">
      <WebCarousel />
    </div>
  );
};

const View = {FeatureIcon, Carousel, Features};
export default View;
