import React from 'react';
import {Text} from 'react-native';

const TextLabel = (res) => {
  const {refs, shadowColor, children, others} = res;
  const stylesArray = [];
  if (res.rubikL) {
    stylesArray.push({fontFamily: 'Rubik-Light'});
  } else if (res.rubikR) {
    stylesArray.push({fontFamily: 'Rubik-Regular'});
  } else if (res.rubikB) {
    stylesArray.push({fontFamily: 'Rubik-Bold'});
  } else if (res.rubikBl) {
    stylesArray.push({fontFamily: 'Rubik-Black'});
  } else if (res.rubikM) {
    stylesArray.push({fontFamily: 'Rubik-Medium'});
  } else if (res.robotoB) {
    stylesArray.push({fontFamily: 'Roboto-Bold'});
  } else if (res.robotoM) {
    stylesArray.push({fontFamily: 'Roboto-Medium'});
  } else if (res.robotoR) {
    stylesArray.push({fontFamily: 'Roboto-Regular'});
  } else if (res.monospace) {
    stylesArray.push({fontFamily: 'monospace'});
  } else {
    stylesArray.push({fontFamily: 'Roboto-Regular'});
  }

  if (res.italic) {
    stylesArray.push({fontStyle: 'italic'});
  }

  stylesArray.push({
    textShadowOffset: {width: 0, height: 1},
    textShadowRadius: 1,
    textShadowColor: shadowColor,
    textTransform: res.transform,
    marginRight: res.mr,
    marginLeft: res.ml,
    marginTop: res.mt,
    marginBottom: res.mb,
    textAlign: res.align,
    color: res.color,
    fontFamily: 'System',
    fontWeight: res.fontWeight,
    fontSize: 18
  });
  stylesArray.push(res.style);

  return (
    <Text
      nativeID={res.nativeID}
     // badge={res.badge}
     // xsmall={res.xsmall}
     // small={res.small}
     // base={res.base}
     // normal={res.normal}
     // medium={res.medium}
     // mediumx={res.mediumx}
     // large={res.large}
     // xlarge={res.xlarge}
     // xxlarge={res.xxlarge}
     // color={res.color}
      style={stylesArray}
      numberOfLines={res.line}
      allowFontScaling={false}
      ref={refs}
      {...others}>
      {res.text}
      {children}
    </Text>
  );
};

TextLabel.defaultProps = {
  line: 1,
  color:'white',
  shadowColor: 'rgba(0, 0, 0, 0.4)',
  mt: 0,
  mb: 0,
  ml: 0,
  mr: 0,
};

export default TextLabel;
