import useSuperAgent from './service';

// eslint-disable-next-line react-hooks/rules-of-hooks
const [agent, global] = useSuperAgent();

export const auth = {
  login: e => agent.post('/auth/login', e),
  phone: e => agent.post(`/auth/phone/login/${e}`),
  register: e => agent.post('/auth/otp/phone/register', e),
  verify: e => agent.post('/auth/otp/phone/verify', e),
  google: e => agent.post('/auth/login/google', e),
  facebook: e => agent.post('/auth/login/facebook', e),
};

export const permission = {
  get: () => agent.get('/permission'),
};

export const me = {
  get: () => agent.get('/me'),
  plan: () => agent.get('/me?includes=plan'),
  update: e => agent.post('/me', e),
  history: () => agent.get('/me/history/song/played'),
  follow: () => agent.get('/me/follow/artist'),
  songFav: () => agent.get('/me/favorite/song'),
  logout: () => agent.post('/me/logout'),
  artistFav: () => agent.get('/me/follow/artist'),
  order: e => agent.post('/me/subscription/midtrans/order', e), // plan_id & order_pattern
  planCreated: () => agent.get('tool/broadcast/subscription/created'),
  subscription: () => agent.get('/me/subscription/smartktv/status'),
};

export const artist = {
  popular: () =>
    agent.get('/artist?order_by=hits_count,desc&hits_recurrence=monthly'), //not use me/follow/artist    smartApp.log('renderItem', is_followed);
  top: () => agent.get('/artist?is_popular=1&order_by=popularity,desc'),
  eng: () =>
    agent.get(
      '/artist?order_by=hits_count,desc&hits_recurrence=monthly?includes=language_id',
    ),
  detail: e => agent.get(`/song?artist_id=${e}&order_by=hits_count,desc`),
  follower: () => agent.get('/me/follow/artist'),
  follow: e => agent.post('/me/follow/artist', e),
  unfollow: e => agent.del('/me/follow/artist', e),
  get: () => agent.get('/artist?is_popular=1&order_by=popularity,desc'),
  all: () => agent.get('/artist?is_followed=0&order_by=popularity,desc'),
  search: e => agent.get(`/artist?name=${e}*`),
};

export const favorite = {
  get: () => agent.get('/me/favorite/song'),
  add: e => agent.post('/me/favorite/song', e),
  del: e => agent.unlike('/me/favorite/song', e),
};
export const artistFavorite = {
  get: () => agent.get('/me/favorite/artist'),
  add: e => agent.post('/me/favorite/artist', e),
  del: e => agent.unlike('/me/favorite/artist', e),
};

export const playlist = {
  save: (pid, sid) => agent.post(`/me/playlist/${pid}/song`, sid),
  create: e => agent.post('/me/playlist', e), //{name:'', is_shared:1||0} true||false
  get: () => agent.get('/me/playlist'),
  add: e => agent.post('/me/playlist/current/song', e),
  toTop: e => agent.put('/me/playlist/song/top', e), // TODO MOVE TO OBJECT BODY playlist_id
  remove: e => agent.del('/me/playlist/song', e),
  delete: e => agent.del(`/me/playlist/${e}`),
  edit: (e, res) => agent.put(`/me/playlist/${e}`, res),
  detail: e => agent.get(`/me/playlist/${e}/song`),
  songDel: e => agent.del(`/me/playlist/${e.pid}/song/${e.sid}`),
  top: e => agent.put(`/me/playlist/${e.pid}/song/${e.sid}/position/top`),
  getFollow: e => agent.get('/me/follow/playlist'),
  follow: e => agent.post('/me/follow/playlist', e), //playlist_id
  unfollow: e => agent.del('/me/follow/playlist', e), //playlist_id
  fav: e => agent.post('/me/favorite/playlist', e),
  delFav: e => agent.del('/me/favorite/playlist', e),
};

export const tracklist = {
  get: () => agent.get('/me/tracklist'),
  add: e => agent.post('/me/tracklist/song', e),
  top: e => agent.put(`/me/tracklist/${e}/position/top`),
  remove: e => agent.del(`/me/tracklist/${e}`),
  addAll: e => agent.post('/me/tracklist/playlist/', e), // playlist_id
};

export const songGet = {
  playlist: () =>
    agent.get('/playlist?is_shared=1&order_by=favorites_count,desc'),
  plistDetail: e => agent.get(`/playlist/${e}/song/`),
  detail: e => agent.get(`/song/${e}?includes=artist`),
  tophit: () =>
    agent.get('/song?order_by=hits_count,desc&hits_recurrence=monthly'),
  newest: () => agent.get('/song?order_by=songs.created_at,desc'),
  search: e =>
    agent.get(
      `/song/search?query=${e}&includes=artist&order_by=hits_count,desc`,
    ), // with specific tag "&tag_names=dangdut" &created_at>2018-01-01 00:00:00
  onTag: ({key, tag}) =>
    agent.get(`/song/search?query=${key}&tag_names=${tag}`),
  tag: () => agent.get('/tag?limit=128'),
  type: e => agent.get(`/tag?type=${e}&limit=20`),
  tagname: e => agent.get(`/song?tag_names=${e}&order_by=hits_count,desc`),
  recommand: e => agent.get(`/song/${e}/recommended`),
  similar: e => agent.get(`/song/${e}/similiar?&order_by=hits_count,desc`),
  lang: e => agent.get(`/song?language_id=${e}&order_by=hits_count,desc`),
};

export const error = {
  get: () => agent.get('/report/error'),
  report: e => agent.post('/report/error', e),
};

export const plan = {
  get: () => agent.get('/plan?order_by=sort,asc'),
  subscription: () => agent.get('/me/subscription'),
};

export const request = e => global.get(e);

export const getInvoice = e =>
  global.post(
    'https://dev-api.smartplay.id/api/me/subscription/xendit/invoice',
    e,
  );
export const sendEmail = async ({name, email, phone, subject, message}) => {
  const nodemailer = require('nodemailer');
  let account = await nodemailer.createTestAccount();
  // Create a SMTP transporter object
  let transporter = nodemailer.createTransport(
    {
      host: account.smtp.host,
      port: account.smtp.port,
      secure: account.smtp.secure,
      auth: {
        user: account.user,
        pass: account.pass,
      },
      logger: false,
      debug: false, // include SMTP traffic in the logs
    },
    {
      // default message fields

      // sender info
      from: 'SmartWeb <no-reply@smart-karaoke.com>',
      headers: {
        'X-Laziness-level': 1000, // just an example header, no need to use this
      },
    },
  );
  /*
  const transporter = nodemailer.createTransport({
    service: 'gmail',
    auth: {
      user: 'developer.smartplay@gmail.com',
      pass: 'smart212121',
    },
  });
*/
  const mailOptions = {
    //from: email,
    to: 'smart.karaoke@live.com, syuga.net@gmail.com',
    subject: `#web-contact-us :: ${subject}`,
    text: `${message} ${name}$ {phone}`,
    html: `<p>${message}</p><br/><p><b>${name}</b><br/>${phone}</p>`,
  };

  await transporter.sendMail(mailOptions, function(error, info) {
    if (error) {
      console.log('error', error);
    } else {
      console.log('Email sent: ' + info.response);
    }
  });
  console.log('sendEmail', mailOptions);
};
