import React, {useEffect, useState} from 'react';
import content from 'commons/content.json';
import SongItem from 'component/card';
import FlatList from 'component/flatlist';
import {View as Viewlib, TextLabel} from 'component';
import {TouchableOpacity, ActivityIndicator, Button} from 'react-native';
import {Container, Row, Image} from 'react-bootstrap';
import View from './View';
import * as Api from 'service-agent';
import './home.css';

const library = [
  {
    id: 'db',
    icon: require('assets/db.png'),
    title: 'Auto Update  Database',
    desc: 'Daily update new  songs  database and show on your dashboard',
  },
  {
    id: 'playlist',
    icon: require('assets/playlist.png'),
    title: 'Manage Playlist',
    desc:
      'Create manage your  playlist  library and share  with your new friends',
  },
  {
    id: 'artist',
    icon: require('assets/artist.png'),
    title: 'Artist You Like',
    desc: "Tell us which artist you like, we'll create an experience for you",
  },
];

const tabs = [
  {id: 0, title: 'New Release'},
  {id: 1, title: 'Popular Songs'},
  {id: 2, title: 'For You'},
  {id: 3, title: 'Shared Playlist'},
  {id: 4, title: 'Your Playlist'},
  {id: 5, title: 'Last Played'},
  {id: 6, title: 'Your Favorite'},
  
];

const sid = '4d06d262-33e2-555d-b72f-1ee33c8c8500';

const Library = () => {
  const [song, setSong] = useState(null);
  const [auth, setAuth] = useState(false);
  const [tophit, setTopHit] = useState(null);
  const [playlist, setPlaylist] = useState(null);
  const [foryou, setForYou] = useState(null);
  const [select, setSelect] = useState(0);

  let data = null;

  useEffect(() => {
    Api.songGet
      .newest()
      .then(setSong)
      .catch(onError);
    return () => {};
  }, []);

  const {title} = content.home.library;

  const renderFitur = (v, i) => {
    return (
      <View.FeatureIcon
        key={v.id}
        icon={v.icon}
        desc={v.desc}
        title={v.title}
      />
    );
  };
  const onError = () => {};
  const getTophit = e => {
    if (e === null) {
      Api.songGet
        .tophit()
        .then(setTopHit)
        .catch(onError);
    }
  };
  const getForYou = e => {
    if (e === null) {
      Api.songGet
        .recommand(sid)
        .then(setForYou)
        .catch(onError);
    }
  };

  const getPlaylist = e => {
    if (e === null && auth) {
      Api.songGet
        .playlist()
        .then(setPlaylist)
        .catch(onError);
    }
  };

  const getSongs = e => {
    if (e === 1) {
      getTophit(tophit);
    } else if (e === 2) {
      getForYou(foryou);
    } else if (e === 3) {
      getPlaylist(playlist);
    }
  };

  const renderItem = ({item, index}) => {
    return <SongItem item={item} />;
  };

  const renderTab = (v, i) => {
    const selected = select === i;
    const onPress = () => {
      setSelect(i);
      getSongs(i);
    };
    return (
      <TouchableOpacity onPress={onPress}>
        <Viewlib
          marginH={10}
          maxWidth={200}
          marginL={i === 3 ? 50 : 10}
          key={i.toString()}>
          <TextLabel
            text={v.title}
            color={selected ? 'white' : '#aaa'}
            fontWeight={selected ? 'bold' : undefined}
          />
          {selected && (
            <Viewlib marginT={8} width={'45%'} height={1} bg={'orange'} />
          )}
        </Viewlib>
      </TouchableOpacity>
    );
  };

  if (select === 0) {
    data = song;
  } else if (select === 1) {
    data = tophit;
  } else if (select === 2) {
    data = foryou;
  } else if (select === 3) {
    data = playlist;
  }

  return (
    <div>
      <Container>
        <Row>
          <div id="library">
            <p className="display-3 text-center">{title}</p>
          </div>
          <Image
            alt="slogan"
            src={require('assets/line.png')}
            className="image-center line"
          />
        </Row>
        <Row>{library.map(renderFitur)}</Row>
      </Container>
      <div className="scroll-wrap">
        <Viewlib row marginT={100}>
          {tabs.map(renderTab)}
        </Viewlib>
      </div>
      {data === null ? (
        <Viewlib centerH centerV marginV={30} height={250}>
          {select > 2 && !auth ? (
            <Viewlib maxWidth={120} height={70}>
              <Button title="Need Login" />
            </Viewlib>
          ) : (
            <ActivityIndicator size="large" color="white" />
          )}
        </Viewlib>
      ) : (
        <FlatList
          song={data}
          renderItem={renderItem}
          setMore={e => setSong(e)}
        />
      )}
    </div>
  );
};

export default Library;
