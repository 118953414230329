import React, {useState} from 'react';
import { FlatList as RNFlatList, ActivityIndicator } from 'react-native';
import disableScroll from 'disable-scroll';
import * as Api from 'service-agent';

const MAX_PAGE = 10;
const MAX_WIDTH = 1440;
const SCROLL_OFFSET = MAX_WIDTH / 2;

const FlatList = res => {
  let scrollRev;
  const [offset, setOffset] = useState(0);
  const [load, setLoad] = useState(false);

  const renderLoader = () => {
    return <ActivityIndicator size="large" color="white" />;
  };

  const scrollTo = async e => {
    if (scrollRev) {
      await scrollRev.scrollToOffset({animated: true, offset: e});
    }
  };

  const onScroll = e => {
    const {x} = e.nativeEvent.contentOffset;
    setOffset(x);
  };

  const onWheel = e => {
    if (!load) {
      const {wheelDelta} = e.nativeEvent;
      if (wheelDelta < 0) {
        scrollTo(offset + SCROLL_OFFSET);
      } else {
        if (offset > 0) {
          disableScroll.on();
        } else {
          disableScroll.off();
        }
        scrollTo(offset - SCROLL_OFFSET);
      }
    }
  };

  const onLoadMore = async e => {
    let arr = {
      data: res.song.data,
      meta: res.song.meta,
      links: res.song.links,
    };
    await Api.request(e)
      .then(async res => {
        arr.meta = res.meta;
        arr.links = res.links;
        await arr.data.push(...res.data);
      })
      .then(async () => {
        await res.setMore(arr);
        await setLoad(false);
      })
      .catch(() => {});
  };

  const onEndReached = e => {
    const {meta, links} = res.song;
    if (!load && meta.current_page < MAX_PAGE) {
      setLoad(true);
      onLoadMore(links.next);
    }
  };

  if (res.song) {
    return (
      <div className="scroll-wrap" onWheel={onWheel}>
        <RNFlatList
          horizontal
          data={res.song.data}
          onScroll={onScroll}
          scrollEventThrottle={1}
          onEndReachedThreshold={0.1}
          renderItem={res.renderItem}
          onEndReached={onEndReached}
          keyExtractor={(item, index) => item.id + index.toString()}
          showsHorizontalScrollIndicator={false}
          ListFooterComponent={renderLoader}
          ListFooterComponentStyle={{
            width: 150,
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          style={{marginVertical: 30}}
          ref={e => (scrollRev = e)}
        />
      </div>
    );
  }
  return renderLoader();
};

export default FlatList;
