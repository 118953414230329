import React from 'react';
import {Container} from 'react-bootstrap';
import content from 'commons/content.json';
import ContactUs from './ContactUs';
import './home.css';

const tv = content.home.footer;

const Footer = () => {
  const renderContact = (v, i) => {
    const smallColl = (
      <div
        className="col-xl-2 col-md-6 col-sm-6 col-12 my-auto"
        key={i.toString()}>
        <ul className="list-unstyled mt-md-3 mt-3">
          <li>
            <p className="mb-4">{v.title}</p>
          </li>
          <li>
            <div className="card-1 border-0">
              <div className="p-0 pl-3">
                <h6 className="mb-2">
                  <b>{v.name}</b>
                </h6>
                <small>{v.wa}</small>
              </div>
            </div>
          </li>
        </ul>
      </div>
    );
    const mediumColl = (
      <div
        className="col-xl-3 col-md-6 col-sm-6 col-12 my-auto"
        key={i.toString()}>
        <ul className="list-unstyled mt-md-3 mt-3">
          <li>
            <p className="mb-4">{v.title}</p>
          </li>
          <li>
            <div className="card-1 border-0">
              <div className="p-0 pl-3">
                <h6 className="mb-2">
                  <b>{v.name}</b>
                </h6>
                <small>{v.wa}</small>
              </div>
            </div>
          </li>
        </ul>
      </div>
    );
    return i === 0 || i === 2 ? mediumColl : smallColl;
  };
  return (
    <Container fluid>
      <div className="footer-wrap">
        <div className="justify-content-center p-0 mx-0">
          <ContactUs />
          <div className="card-0 border-0">
            <div className=" px-5">
              <div className="footer-title">
                <h3>Direct Whatsapp Message</h3>
              </div>
              <div
                className="row justify-content-around mb-0 pt-5"
                style={{paddingLeft: 20}}>
                {tv.contact.map(renderContact)}
              </div>
              <div className="row justify-content-around mb-0 py-5 ">
                <div className="col-xl-auto col-md-6 col-sm-6 co-12 pt-4">
                  <ul className="list-unstyled">
                    <li className="mt-md-0 mt-4 text-center">We are social</li>
                    <li>
                      <a href="#" className="fa fa-facebook" />
                      <a href="#" className="fa fa-twitter" />
                      <a href="#" className="fa fa-instagram" />
                      <a href="#" className="fa fa-linkedin" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr className="colored" />
        </div>
      </div>
    </Container>
  );
};

export default Footer;
