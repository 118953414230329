import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    margin: 10,
    background: '#121212',
  },
  media: {
    height: 140,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
}));

export default function SongItem({ item }) {
  const { artist_name, title, artist_picture_file } = item;
  //const [expanded, setExpanded] = React.useState(false);
  const classes = useStyles();
  //const handleExpandClick = () => {    setExpanded(!expanded);  };
  return (
    <div className="item">
      <Card className={classes.root}>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={artist_picture_file.box_medium}
            title="Contemplative Reptile"
          />
          <CardContent>
            <Typography gutterBottom variant="h6" component="h2">
              {title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {artist_name}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
}
