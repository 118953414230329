import React from 'react';
import { Navbar } from 'react-bootstrap';

const BrandNav = ({ alt, src }) => {
	return (
		<Navbar.Brand>
			<img alt={alt} src={src} className="Smart-logo" width='180' height='40' />
		</Navbar.Brand>
	);
};

export default BrandNav;
