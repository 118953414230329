import React, {useState} from 'react';
import {TextInput, Button, View, StyleSheet} from 'react-native';
import * as Api from 'service-agent';

const WARN = '#f5ea92';

export default function ContactUs() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState({value: '', valid: false});
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  let emailRev;
  let nameRev;
  let phoneRev;
  let subjectRev;
  let messageRev;

  const validateEmail = text => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(text) === false) {
      setEmail({value: text, valid: false});
      return false;
    } else {
      setEmail(text);
      setEmail({value: text, valid: true});
    }
  };

  const getValidation = e => {
    let isValid = true;
    const backgroundColor = '#f5ea92';
    if (!e.name) {
      nameRev.focus();
      nameRev.setNativeProps({style: {backgroundColor}});
      isValid = false;
    } else if (!email.valid) {
      emailRev.focus();
      emailRev.setNativeProps({style: {backgroundColor}});
      isValid = false;
    } else if (!e.phone) {
      phoneRev.focus();
      phoneRev.setNativeProps({style: {backgroundColor}});
      isValid = false;
    } else if (!e.subject) {
      subjectRev.focus();
      subjectRev.setNativeProps({style: {backgroundColor}});
      isValid = false;
    } else if (!e.message) {
      messageRev.focus();
      messageRev.setNativeProps({style: {backgroundColor}});
      isValid = false;
    }
    return isValid;
  };

  const onSubmit = e => {
    const param = {
      name: name,
      email: email.value,
      phone: phone,
      subject: subject,
      message: message,
    };
    const isValid = getValidation(param);
    if (isValid) {
      Api.sendEmail(param).catch(e => {
        console.log('catch', e);
      });
    }
  };

  return (
    <div className="contact-form">
      <div className="contact-image">
        <img
          src="https://image.ibb.co/kUagtU/rocket_contact.png"
          alt="rocket_contact"
        />
      </div>
      <form method="post">
        <h3>Drop Us a Message</h3>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <TextInput
                style={st.input}
                placeholder="Your Name *"
                onChangeText={text => setName(text)}
                ref={e => (nameRev = e)}
                value={name}
              />
            </div>
            <div className="form-group">
              <TextInput
                style={st.input}
                placeholder="Your Email *"
                onChangeText={text => validateEmail(text)}
                ref={e => (emailRev = e)}
                value={email.value}
              />
            </div>
            <div className="form-group">
              <TextInput
                style={st.input}
                maxLength={18}
                keyboardType="numeric"
                placeholder="Your Phone Number *"
                onChangeText={text => setPhone(text)}
                ref={e => (phoneRev = e)}
                value={phone}
              />
            </div>
            <div className="form-group">
              <TextInput
                style={st.input}
                placeholder="Subject *"
                onChangeText={text => setSubject(text)}
                ref={e => (subjectRev = e)}
                value={subject}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <TextInput
                style={st.textArea}
                ref={e => (messageRev = e)}
                onChangeText={text => setMessage(text)}
                placeholder={'Your Message *'}
                numberOfLines={10}
                multiline={true}
              />
            </div>
          </div>
          <View style={{width: 250, marginHorizontal: 15}}>
            <Button title="Send Message" onPress={onSubmit} />
          </View>
        </div>
      </form>
    </div>
  );
}

const st = StyleSheet.create({
  input: {
    height: 40,
    width: '100%',
    borderColor: 'gray',
    borderWidth: 1,
    backgroundColor: '#ccc',
    fontSize: 16,
    color: 'black',
    paddingHorizontal: 10,
  },
  textArea: {
    width: '100%',
    height: 210,
    borderWidth: 2,
    borderColor: '#9E9E9E',
    borderRadius: 20,
    backgroundColor: '#ccc',
    padding: 10,
    fontSize: 16,
  },
});
