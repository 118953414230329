import React from 'react';
import {View as RNView} from 'react-native';

export default function View(res) {
  const styleArr = [];

  res.flex && styleArr.push({flex: 1});
  res.row && styleArr.push({flexDirection: 'row'});
  res.centerH && styleArr.push({alignItems: 'center'});
  res.centerV && styleArr.push({justifyContent: 'center'});
 
  styleArr.push({
    width: res.width,
    height:res.height,
    maxWidth: res.maxWidth,
    backgroundColor: res.bg,
    marginTop: res.marginT,
    marginLeft: res.marginL,
    marginRight: res.marginR,
    marginBottom: res.marginB,
    marginVertical: res.marginV,
    marginHorizontal: res.marginH,
  });

  res.style && styleArr.push(res.style);
  return <RNView style={styleArr}>{res.children}</RNView>;
}
