import React from 'react';
import HeadLine from './HeadLine';
import Library from './Library';
import AndroidTv from './AndroidTv';
import OwnBrand from './Brand';
import Footer from './Footer';
import {ThemeProvider, createMuiTheme} from '@material-ui/core';
import './home.css';

const Home = () => {
  const darkTheme = createMuiTheme({palette: {type: 'dark'}});
  return (
    <ThemeProvider theme={darkTheme}>
      <div>
        <HeadLine />
        <Library />
        <AndroidTv />
        <OwnBrand />
        <Footer />
      </div>
    </ThemeProvider>
  );
};
export default Home;
