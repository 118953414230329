import React from 'react';
import './App.css';
import {Switch, Route, BrowserRouter} from 'react-router-dom';
import {Navigation} from './Navigation';
import {brandLogoSrc as src} from './Navigation/BrandNav';
import {Home} from './Screen/Home';
//import {NotFound} from './Screen/NotFound';
//import {Vehicule} from './Screen/Vehicule';
//import {Service} from './Screen/Service';
//import {Contact} from './Screen/Contact';
import LINKS, {connexionLink} from './constant';

function App() {
  const brandLogo = {src, alt: 'brand logo'};

  return (
    <React.Fragment>
      <BrowserRouter>
        <Navigation
          {...brandLogo}
          navLinks={LINKS}
          connexionLink={connexionLink}
        />
        <Home />
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;

/*
return (
		<React.Fragment>
			<BrowserRouter>
				<Navigation {...brandLogo} navLinks={LINKS} connexionLink={connexionLink} />
				<Switch>
					<Route exact path={process.env.PUBLIC_URL + '/'} component={Home} />
					<Route path='/vehicules' component={Vehicule} />
					<Route path='/services' component={Service} />
					<Route path='/contact' component={Contact} />
					<Route component={NotFound} />
				</Switch>
			</BrowserRouter>
		</React.Fragment>
	);
	*/
