import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import content from 'commons/content.json';
import View from './View';
import './home.css';

const tv = content.home.androidtv;

const library = [
  {
    id: 'voice',
    icon: require('assets/voice.png'),
    title: tv.features[0].title,
    desc: tv.features[0].subtitle,
  },
  {
    id: 'cast',
    icon: require('assets/cast.png'),
    title: tv.features[1].title,
    desc: tv.features[1].subtitle,
  },
  {
    id: 'remote',
    icon: require('assets/remote.png'),
    title: tv.features[2].title,
    desc: tv.features[2].subtitle,
  },
];

const AndroidTv = () => {
  const renderFitur = (v, i) => {
    return (
      <View.FeatureIcon
        key={v.id}
        icon={v.icon}
        desc={v.desc}
        title={v.title}
      />
    );
  };
  return (
    <Container fluid id="tvBg">
      <Container>
        <Row>
          <div id="antv">
            <p className="display-3 text-center">{tv.title}</p>
          </div>
        </Row>
        <Row><View.Carousel /></Row>
        <Row>{library.map(renderFitur)}</Row>
      </Container>
    </Container>
  );
};

export default AndroidTv;
